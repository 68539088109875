<template>
  <div :class="{ gris: gris }">
    <div :class="['loader', { active: loading }]">
      <img src="@/assets/loading.gif" :alt="$t('kargatzen')" />
    </div>
    <dialog id="mezua" ref="mezua" @click="mezuaItxi">
      <button type="button" class="btn-close-modal" @click="itxiMezua">
        <img src="@/assets/delete-modal.svg" :alt="$t('itxi')" />
      </button>
      Ezin izan da informazioa jaitsi, saiatu berriro.
    </dialog>
    <Edukia
      :irekita="edukiaIrekita"
      :id="edukiaId"
      @itxiEdukia="onItxiEdukia"
      @irekiEdukia="onIrekiEdukia"
      @loading="onLoading"
      @mezua="onMezua"
    />
    <div id="cabecera">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Durangaldea, izaera esencia" />
      </div>

      <div id="fuente">
        <button
          type="button"
          class="increase"
          @click="cambiarTamañoFuente(1.1)"
        ></button>
        <button
          type="button"
          class="decrease"
          @click="cambiarTamañoFuente(0.9)"
        ></button>
      </div>

      <div id="color">
        <button type="button" class="tono-color" @click="tonoColor"></button>
        <button type="button" class="tono-gris" @click="tonoGris"></button>
      </div>

      <div class="hizkuntzak">
        <!-- <ul class="hizkuntzak-lista">
          <li :class="['hizkuntzak-item', { active: locale == 'eu' }]">
            <a href="#" hreflang="eu" @click.prevent="cambiarIdioma('eu')"
              >EU</a
            >
          </li>
          <li :class="['hizkuntzak-item', { active: locale == 'es' }]">
            <a href="#" hreflang="es" @click.prevent="cambiarIdioma('es')"
              >ES</a
            >
          </li>
          <li :class="['hizkuntzak-item', { active: locale == 'en' }]">
            <a href="#" hreflang="en" @click.prevent="cambiarIdioma('en')"
              >EN</a
            >
          </li>
          <li :class="['hizkuntzak-item', { active: locale == 'fr' }]">
            <a href="#" hreflang="fr" @click.prevent="cambiarIdioma('fr')"
              >FR</a
            >
          </li>
        </ul> -->

        <div class="custom-select">
          <select @change="cambiarIdiomaSelect">
            <option value="eu">EU</option>
            <option value="es">ES</option>
            <option value="en">EN</option>
            <option value="fr">FR</option>
          </select>
        </div>

        <button
          type="button"
          :class="['btn-filtros', { active: filtroAbierto }]"
          @click="abrirFiltros"
        >
          <span>{{ $t("filtroak") }}</span>
        </button>
      </div>
    </div>
    <Mapa
      :herriak="ingurua.herriak"
      :filtroak="filtroak"
      :atala="atalBerria"
      :kokapena="kokapena"
      :herria="herriBerria"
      :filtroakezabatu="filtroakEzabatu"
      :nonOrain="nonOrain"
      :cargado="cargado"
      @markaClick="onMarkaClick"
      @zenbataktibo="onZenbataktibo"
    />
    <Filtros
      :abierto="filtroAbierto"
      :ingurua="ingurua"
      :filtroak="filtroak"
      :zenbataktibo="zenbatAktibo"
      @atala="onAtala"
      @emaitzaikusi="abrirFiltros"
      @herria="onHerria"
      @filtroakezabatu="onFiltroakezabatu"
    />
  </div>
</template>

<script setup>
import { ref, nextTick, onMounted, provide } from "vue";
import Edukia from "@/components/Edukia.vue";
import Mapa from "@/components/Mapa.vue";
import Filtros from "@/components/Filtros.vue";
import { useI18n } from "vue-i18n";

const filtroAbierto = ref(true);
const edukiaIrekita = ref(false);
const edukiaId = ref(-1);
const filtroakEzabatu = ref(false);

const loading = ref(true);
const cargado = ref(false);
const atalBerria = ref({
  id: 0,
  activo: false,
});

const herriBerria = ref({
  id: 0,
  activo: false,
});

const ingurua = ref({});

const filtroak = ref([]);

const zenbatAktibo = ref(0);

const { locale } = useI18n();

const watchId = ref(null);
const kokapena = ref({});
const nonOrain = ref(null);
const mezua = ref(null);
const gorputza = ref(null);
const gris = ref(false);

const tamanoFuente = ref(15);

provide("tamanoFuente", tamanoFuente);

const clickEvent = ref(null);

onMounted(() => {
  gorputza.value = document.querySelector("html");
});

async function getDatos() {
  //loading.value = true;

  // npx json-server mapa.json (desde la carpeta static)
  //let response = await fetch("http://localhost:3000/mapa");
  /*let response = await fetch(
    "https://mapa.durangaldeaturismoa.loc/mapa.php?idioma=" + locale.value
  );*/

  loading.value = true;
  cargado.value = false;
  let response = await fetch(
    "https://mapa.durangaldeaturismoa.eus/mapa.php?idioma=" + locale.value
  );
  if (response.ok) {
    let result = await response.json();
    ingurua.value = result.ingurua;
    filtroak.value = result.filtroak;

    filtroak.value.forEach((filtroa) => {
      filtroa.atalak.forEach((atala) => {
        atala.markak.forEach((marka) => {
          if (marka.activo) zenbatAktibo.value++;
        });
      });
    });

    watchId.value = navigator.geolocation.watchPosition(
      mostrarPosicion,
      gestionarErrores,
      { enableHighAccuracy: true, maximumAge: 0 }
    );

    loading.value = false;
    cargado.value = true;
  } else {
    console.log("error");
  }
}

function onLoading(load) {
  loading.value = load;
}

function abrirFiltros() {
  filtroAbierto.value = !filtroAbierto.value;
}

function onAtala(atala) {
  for (let x = 0; x < filtroak.value.length; x++) {
    for (let y = 0; y < filtroak.value[x].atalak.length; y++) {
      if (filtroak.value[x].atalak[y].id == atala.id) {
        filtroak.value[x].atalak[y].activo = atala.activo;
      }
    }
  }
  atalBerria.value = {
    id: atala.id,
    activo: atala.activo,
  };
}

function onMarkaClick(id) {
  if (edukiaId.value == id) edukiaIrekita.value = true;
  else edukiaId.value = id;
}

function onItxiEdukia() {
  edukiaIrekita.value = false;
}

function onIrekiEdukia() {
  edukiaIrekita.value = true;
}

function onHerria(herria) {
  const herriak = ingurua.value.herriak;
  for (let x = 0; x < herriak.length; x++) {
    if (herriak[x].id == herria.id) {
      herriak[x].activo = herria.activo;

      herriBerria.value = {
        id: herria.id,
        activo: herria.activo,
      };
    }
  }
}

function onFiltroakezabatu() {
  filtroak.value.forEach((filtroa) => {
    filtroa.atalak.forEach((atala) => {
      atala.activo = false;
    });
  });
  filtroakEzabatu.value = true;
  nextTick(() => {
    filtroakEzabatu.value = false;
  });
}

function onZenbataktibo(zenbat) {
  zenbatAktibo.value = zenbat;
}

function onMezua() {
  mezua.value.showModal();
  edukiaId.value = -1;
}

function itxiMezua() {
  mezua.value.close();
  loading.value = false;
}

function mezuaItxi(evt) {
  clickEvent.value = evt;
  const dialogJoangoNaizDimensions = mezua.value.getBoundingClientRect();
  if (
    clickEvent.value.clientX < dialogJoangoNaizDimensions.left ||
    clickEvent.value.clientX > dialogJoangoNaizDimensions.right ||
    clickEvent.value.clientY < dialogJoangoNaizDimensions.top ||
    clickEvent.value.clientY > dialogJoangoNaizDimensions.bottom
  ) {
    mezua.value.close();
    loading.value = false;
  }
}

function cambiarIdioma(idioma) {
  locale.value = idioma;
  zenbatAktibo.value = 0;
  filtroak.value = [];
  edukiaId.value = -2; // Indica cambio de idioma
  getDatos();
}

function cambiarIdiomaSelect(event) {
  cambiarIdioma(event.target.value);
}

function getFontSize() {
  return parseFloat(
    window.getComputedStyle(gorputza.value, null).getPropertyValue("font-size")
  );
}

function cambiarTamañoFuente(factor) {
  let fontSize = getFontSize() * factor + "px";
  document.documentElement.style.setProperty("font-size", fontSize);
  tamanoFuente.value = parseFloat(fontSize) + 4;
}

function tonoColor() {
  gris.value = false;
}

function tonoGris() {
  gris.value = true;
}

function mostrarPosicion(posicion) {
  /* var datos='';
    datos+='Latitud: '+posicion.coords.latitude+'<br>';
    datos+='Longitud: '+posicion.coords.longitude+'<br>';
    datos+='Exactitud: '+posicion.coords.accuracy+' metros.<br>';
    console.log(datos); */

  // Dinamikoa
  kokapena.value = {
    lat: posicion.coords.latitude,
    lng: posicion.coords.longitude,
  };

  fetch(
    "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      posicion.coords.latitude +
      "," +
      posicion.coords.longitude +
      "&key=AIzaSyDD2RIbEYHqgVlqPaij9rwS43rvuJC1Cis"
  )
    // Abadiño
    /* kokapena.value = {
        lat: 43.15220700904001,
        lng: -2.6020872498806438
    } */
    //fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=43.15220700904001,-2.6020872498806438&key=AIzaSyDD2RIbEYHqgVlqPaij9rwS43rvuJC1Cis')

    // Amorebieta-Etxano
    /* kokapena.value = {
        lat: 43.22269169982061,
        lng: -2.7400790897468665
    } */
    //fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=43.22269169982061,-2.7400790897468665&key=AIzaSyDD2RIbEYHqgVlqPaij9rwS43rvuJC1Cis')

    // Atxondo
    /* kokapena.value = {
        lat: 43.13114206460557,
        lng: -2.586759828271396
    } */
    //fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=43.13114206460557,-2.586759828271396&key=AIzaSyDD2RIbEYHqgVlqPaij9rwS43rvuJC1Cis')

    // Berriz
    /* kokapena.value = {
        lat: 43.17714174135879,
        lng: -2.578309592194792
    } */
    //fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=43.17714174135879,-2.578309592194792&key=AIzaSyDD2RIbEYHqgVlqPaij9rwS43rvuJC1Cis')

    // Durango
    /* kokapena.value = {
        lat: 43.172213099475385,
        lng: -2.637493662957633
    } */
    //fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=43.172213099475385,-2.637493662957633&key=AIzaSyDD2RIbEYHqgVlqPaij9rwS43rvuJC1Cis')

    // Elorrio
    /* kokapena.value = {
        lat: 43.13324356805958,
        lng: -2.541235370550176
    } */
    //fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=43.13324356805958,-2.541235370550176&key=AIzaSyDD2RIbEYHqgVlqPaij9rwS43rvuJC1Cis')

    .then((resp) => resp.json())
    .then((geocodingResult) => {
      let helbidea = geocodingResult.results[0].formatted_address;

      let indexHerria = -1;

      ingurua.value.herriak.forEach((herria, i) => {
        if (nonOrain.value != herria.id) {
          if (helbidea.includes(herria.nombre)) {
            indexHerria = i;
          }
        }
      });

      if (indexHerria != -1) {
        ingurua.value.herriak.forEach((herria, i) => {
          ingurua.value.herriak[i].activo = false;
        });
        nonOrain.value = ingurua.value.herriak[indexHerria].id;
        ingurua.value.herriak[indexHerria].activo = true;
        herriBerria.value = {
          id: ingurua.value.herriak[indexHerria].id,
          activo: true,
        };
        indexHerria = -1;
      }
    });
}

function gestionarErrores(error) {
  /* console.log(locale("konexioa")); */
}

getDatos();
</script>

<style>
html {
  font-size: 62.5%;
  min-height: 100%;
  position: relative;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: #000000;
  overflow: hidden;
}
h2 {
  font-size: 3.8rem;
  line-height: 4.2rem;
  margin-top: 2rem;
}
div {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.gris img {
  filter: grayscale(100%);
}

.gris .fondo-marcadores {
  background-color: #fff !important;
}

.gris p.mota {
  color: #000000 !important;
}

.gris .borde {
  background-image: none !important;
  background-color: #000000 !important;
}

#fuente {
  margin-right: 1rem;
}

#fuente button,
#color button {
  border: 0;
  margin: 0 1rem 0 0;
  background-size: contain;
  cursor: pointer;
}

#fuente button:last-child,
#color button:last-child {
  margin-right: 0;
}

#color {
  margin-left: 1rem;
}

.increase {
  background: url(@/assets/increase-icon.svg) 50% 50% no-repeat;
  height: 30px;
  width: 30px;
}

.decrease {
  background: url(@/assets/decrease-icon.svg) 50% 50% no-repeat;
  width: 20px;
  height: 20px;
}

.tono-color {
  background: url(@/assets/droplet-icon-color.svg) 50% 50% no-repeat;
  height: 35px;
  width: 35px;
  margin-right: 0.5rem !important;
}
.tono-gris {
  background: url(@/assets/droplet-icon-gris.svg) 50% 50% no-repeat;
  height: 35px;
  width: 35px;
}

.full-screen {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.47);
  border: 0;
  width: 49px;
  height: 49px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.full-screen img {
  width: 26px;
  height: 26px;
}

#edukia {
  position: fixed;
  z-index: 100;
}
.edukia {
  position: fixed;
  background-color: #fff;
  padding: 3rem;
  top: 0;
  right: -100%;
  bottom: 0;
  left: auto;
  box-shadow: -10px 4px 60px 0px rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  z-index: 1;
  width: 100%;
  overflow-y: auto;
}
.fondoa {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(36, 36, 36, 0.22);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
#edukia.active .edukia {
  right: 0;
}
#edukia.active .fondoa {
  opacity: 1;
  visibility: visible;
}

.custom-select {
  position: relative;
}

.custom-select select {
  padding: 0;
  border: 0 !important;
  font-size: 2rem;
  cursor: pointer;
  /* margin-right: 2rem; */
}

.custom-select select:focus {
  outline: none;
}

#carrusel {
  width: 100%;
  position: relative;
}

.swiper {
  padding-bottom: 30px;
  height: 335px;
}

.swiper-slide {
  background-color: #efefef;
}

.img-swiper {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.swiper-pagination {
  text-align: left;
}

:fullscreen .swiper {
  padding-bottom: 0;
  height: 100% !important;
}

:fullscreen .img-swiper {
  /* object-fit: cover !important; */
  object-position: center !important;
  height: 100% !important;
}

:fullscreen .swiper-pagination {
  text-align: center;
  bottom: 100px;
}

.swiper-pagination-bullet {
  -webkit-border-radius: 0;
  border-radius: 0;
  width: 48px;
  height: 4px;
  background-color: #c2c2c2;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: #000000;
}

.swiper-button-prev,
.swiper-button-next {
  top: auto;
  bottom: 0;
  z-index: 11;
  width: 38px;
  right: 0;
}
.swiper-button-prev {
  left: auto;
  right: 60px;
}

:fullscreen .swiper-button-prev {
  bottom: auto;
  top: 50%;
  right: auto;
  left: 2rem;
}

:fullscreen .swiper-button-next {
  bottom: auto;
  top: 50%;
  right: 2rem;
}

.swiper-button-prev:after {
  background: url(@/assets/prev.svg) right 50% no-repeat;
  background-size: contain;
  color: transparent;
  width: 38px;
}

.swiper-button-next:after {
  background: url(@/assets/next.svg) right 50% no-repeat;
  color: transparent;
  background-size: contain;
  width: 38px;
}

.btn-map {
  text-decoration: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  color: #000000;
  font-weight: 500;
  font-size: 1.5rem;
  padding: 0.5rem 1.5rem 0.5rem 3.5rem;
  background: #d9d9d9 url(@/assets/mapa.svg) 1rem 50% no-repeat;
}
.web {
  text-decoration: none;
  color: #000000;
  padding-right: 30px;
  background: url(@/assets/joan.svg) 100% 100% no-repeat;
}
.filtros {
  background: #fff;
  box-shadow: -10px 0px 60px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  position: fixed;
  top: 75px;
  bottom: 0;
  z-index: 1;
  right: -100%;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  padding-bottom: 103px;
}
.lista-filtros {
  overflow-y: auto;
  height: 100%;
}
.filtro,
.herriak {
  border-bottom: 1px solid #d9d9d9;
  padding: 0.6rem 0;
  position: relative;
}

.filtro span.borde,
.herriak span.borde {
  content: "";
  position: absolute;
  top: 0.6rem;
  left: 0.7rem;
  bottom: 0.6rem;
  z-index: 100;
  width: 3px;
  background-color: #000000;
}
.btn-filtros {
  height: 51px;
  width: 51px;
  color: #fff;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  padding: 0;
  background: #000000 url(@/assets/ireki.svg) 50% 50% no-repeat;
  margin-left: 0.5rem;
}
.btn-edukia {
  height: 51px;
  width: 51px;
  position: absolute;
  top: 12px;
  right: 12px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  padding: 0 2.5rem 0 2.5rem;
  background: #000000 url(@/assets/itxi.svg) 50% 50% no-repeat;
}
.btn-filtros.active {
  background-image: url(@/assets/itxi.svg);
}
.btn-filtros span {
  display: none;
}
.filtros.active {
  right: 0;
}
.mota {
  margin: 0.5rem 0;
}
.mota span {
  margin-right: 1rem;
  position: relative;
  top: 6px;
}
.helbidea span,
.telefonoa span {
  display: block;
}
.ordutegia {
  font-size: 1.5rem;
  color: rgba(37, 37, 37, 0.7);
  margin-top: -2rem;
}
.ordutegi-titularra {
  font-size: 1.2rem;
  letter-spacing: 2px;
}
.botonera {
  height: 103px;
  border-top: 1px solid #d9d9d9;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  align-items: center;
}
.botonera .btn-lateral {
  flex: 1;
  text-align: center;
}
.btn-lateral button {
  padding: 1rem 1rem;
  border: 1px solid #000000;
  background-color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: 700;
  cursor: pointer;
}
.btn-lateral button.btn-emaitza {
  background-color: #000000;
  color: #fff;
}
.mapa,
.vue-map-container {
  position: relative;
  height: calc(100vh - 75px) !important;
}

.logoak {
  position: absolute;
  left: 1rem;
  bottom: 1.5rem;
  display: -webkit-inline-flex;
  display: inline-flex;
  flex-flow: row nowrap;
  z-index: 1;
}

.logobat {
  height: 72px;
  padding: 0.5rem 1rem;
  background-color: #fff;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: 0 0.5rem;
}

.logobat img {
  height: 100%;
}

.btn-fullscreen {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

#map {
  width: 100%;
  height: calc(100vh - 75px);
}
#cabecera {
  height: 75px;
  background-color: #fff;
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  z-index: 2;
}
.logo {
  align-items: center;
  display: -webkit-inline-flex;
  display: inline-flex;
  flex: 1;
}
.logo img {
  width: 100px;
}
.hizkuntzak {
  display: -webkit-inline-flex;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}
.hizkuntzak-lista {
  display: -webkit-inline-flex;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  float: right;
  padding-left: 0;
  list-style: none;
}
.hizkuntzak-item {
  margin-right: 1.3rem;
}
.hizkuntzak-item a {
  text-decoration: none;
  color: rgba(37, 37, 37, 0.7);
}
.hizkuntzak-item.active a {
  font-weight: 600;
  color: #000000;
}
.accordion {
  color: #000000;
  cursor: pointer;
  padding: 0 1rem 0 0.7rem;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  font-size: 1.4rem;
  text-transform: uppercase;
  position: relative;
  font-weight: 600;
  background-color: #fff;
  letter-spacing: 1.75px;
}
.accordion span.titulo {
  padding-left: 2.5rem;
  height: 6.5rem;
  line-height: 4rem;
  display: block;
  position: relative;
}
.accordion:after {
  content: "\02EF";
  font-size: 4rem;
  line-height: 0;
  color: #000000;
  margin-left: 5px;
  margin-top: -1rem;
  position: absolute;
  top: calc(50% - 8px);
  right: 1rem;
}
.accordion.active:after {
  content: "\02F0";
}
.panel {
  padding: 0 1.8rem 0 0.7rem;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.5s ease-out;
  transition: max-height 0.5s ease-out;
}
.panel ul {
  list-style: none;
  padding-left: 2.5rem;
  margin-top: 0;
  margin-bottom: 0;
}
.panel button {
  width: 100%;
  text-align: left;
  padding: 1.5rem 1rem 1.5rem 43px;
  border: 0;
  background-color: #fff;
  position: relative;
}
.icono-activo {
  position: absolute;
  top: 4px;
  left: 0;
  width: 37px;
  height: 37px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  z-index: 2;
}
.icono-inactivo {
  position: absolute;
  top: 4px;
  left: 0;
  z-index: 1;
}

.bola {
  width: 34px;
  height: 34px;
  display: inline-block;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #000;
  position: absolute;
  top: 0;
  left: 0;

  opacity: 0.4;
}
.icono {
  position: relative;
  width: 34px;
  height: 34px;
  padding: 0;
  display: inline-block;
  border: 1px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}
.icono img,
svg.svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.iconos {
  display: -webkit-inline-flex;
  display: inline-flex;
  flex-flow: row nowrap;
}
.icono-txiki {
  position: absolute;
  left: 2.5rem;
  right: 0;
  bottom: -3px;
  display: -webkit-inline-flex;
  display: inline-flex;
}
.icono-txiki span {
  flex: 1 30px;
  max-width: 35px;
}
.icono-txiki img {
  max-width: 23px;
  max-height: 16px;
  opacity: 0.4;
}
.btn-marcadores {
  position: relative;
  cursor: pointer;
}
.fondo-marcadores {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: -0.7rem;
  border-bottom: 1px solid #fff;
  opacity: 0;
  /* font-size: 1.6rem; */
}
.fondo-marcadores.active {
  opacity: 0.2;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.loader.active {
  opacity: 1;
  visibility: visible;
}
.loader img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.gm-fullscreen-control {
  display: none;
}

.drop {
  background: orange;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
}

.drop .drop-punta {
  content: "";
  position: absolute;
  bottom: -28px;
  left: 50%;
  border: 15px solid transparent;
  border-top: 20px solid orange;
  transform: translateX(-50%);
}

.drop-texto {
  position: relative;
  text-align: center;
}

dialog {
  border: 1px solid #707070;
  padding: 5rem 4rem 4rem 4rem;
  max-width: calc(100vw - 5rem);
  text-align: center;
}

.btn-close-modal {
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  border: 0;
  background-color: #fff !important;
}

iframe {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.deskribapena {
  padding-bottom: 3rem;
}
@media (min-width: 576px) {
  #cabecera {
    padding-left: 3rem;
    padding-right: 2rem;
  }
  .logo img {
    width: 150px;
  }
  .btn-filtros {
    width: 160px;
    background-position: calc(100% - 2rem) 50%;
    padding: 0 6rem 0 3rem;
    margin-left: 2rem;
  }
  .btn-filtros span {
    display: inline-block;
  }
  .hizkuntzak-item {
    margin-right: 2rem;
  }
  .btn-lateral button {
    padding: 1.5rem 2rem;
    font-size: 1.4rem;
  }

  .filtros {
    width: calc(100% - 90px);
    right: calc(-100% + 90px);
  }
}
@media (min-width: 768px) {
  .filtros {
    width: 500px;
    right: -500px;
  }
}
@media (min-width: 992px) {
  .logoak {
    left: 2rem;
    bottom: 2.5rem;
  }

  .logobat {
    height: 92px;
    padding: 1rem 1.5rem;
    margin: 0 0.5rem;
  }
  .swiper {
    padding-bottom: 40px;
    height: 635px;
  }
  .filtros {
    width: 595px;
    right: -595px;
  }
  .edukia {
    padding: 6rem;
    display: -webkit-flex;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    flex-flow: row-reverse nowrap;
    width: auto;
  }
  #info {
    width: 35vw;
  }
  #carrusel {
    width: 45vw;
    padding-right: 4vw;
  }

  #carrusel.full {
    width: 100vw !important;
    padding-right: 0;
    max-height: 100vh !important;
  }

  .full-screen {
    right: calc(4vw + 20px);
  }
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
  h2 {
    font-size: 4.4rem;
    line-height: 5rem;
  }
  #carrousel {
    padding-right: 8vw;
  }

  #info {
    width: 30vw;
  }
  #carrusel {
    width: 45vw;
  }
}
@media (min-width: 1600px) {
  .edukia {
    padding: 10rem;
  }
  #info {
    width: 25vw;
  }
  #carrusel {
    width: 48vw;
  }
}
</style>
